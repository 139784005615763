.wrapper {
	@include grid-column;
}
// Grid
.header-container {
	display: block;
	padding-top: rem-calc(12);
	padding-bottom: rem-calc(22);
	.header {
		@include grid-row;
		@include grid-column;
		@include grid-column-position(center);
	}
}
.feature {
	.highlighted {
		@include grid-row;
		// @include grid-column-position(center);
		h1 {
			@include grid-column(10);
		}
	}
}
.main-container {
	.main {
		@include grid-row;
		@include grid-column;
		@include grid-column-position(center);
		.eq-wrapper {

		}
	}
}
.center_column {
	.region-center-column {
		@include grid-row;
		@include grid-column;
		@include grid-column-position(center);
	}
}

.insight-teaser {
	@include grid-row($behavior: nest);
	@include auto-width(9, div);
	.medium-4 {
		.callout {
			margin-bottom: rem-calc(20);
		}
	}
}
#block-bean-register-copy {
	@include grid-row;
	padding-left: rem-calc(10);
	padding-right: rem-calc(10);
}
#block-bean-login-copy {
	padding-top: rem-calc(30);
}
.footer-container {
	@include grid-row;
	max-width: 100%;
	background-color: $black;
	color: $white;
}
.footer {
	@include grid-row;
	@include grid-column;
	@include grid-column-position(center);
	padding-top: rem-calc(80);
	padding-bottom: rem-calc(80);
}
