.page-taxonomy {
	.main-container {
		.main {
			padding-left: 0;
			padding-right: 0;
		}
	  position: relative;
	  z-index: 1;
	  height: auto;
		.full-width-bg {
		    position: absolute;
		    z-index: -1;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    background: url('../img/images/insight-map.png') no-repeat center -30px fixed;
		    background-size: 120%;
		    // -webkit-background-size: cover;
		    //  -moz-background-size: cover;
		    //  -o-background-size: cover;
		    //  background-size: cover;
		    opacity: 0.1;
		    width: 100%;
		    height: 100%;
		}
	}
	#block-bean-insight-post-bottom {
		padding-bottom: rem-calc(58);
		padding-left: rem-calc(10);
	  a:link, a:hover {
	    text-decoration: underline;
	  }
	}
}
.view-header {
	.title {
		padding-left: rem-calc(10);
		text-transform: capitalize;
	}
	.search-summery {
		padding-left: rem-calc(10);
	}
}
.page-search {
	.main {
		padding-left: 0;
		padding-right: 0;
	}
	.view-header {
		.title {
			margin-bottom: 0;
			line-height: rem-calc(50);
		}
		.search-summery {
			padding-left: rem-calc(10);
			margin-bottom: rem-calc(8);
		}
	}
}
.view-content {
	.masonry-item {
		padding: 0;
		border: solid 0px #cccccc;
		background-color: transparent;
		margin-left: 0;
		margin-right: 0;
		margin: 0;
		@include grid-column(12);
		@include breakpoint(medium) {
			@include grid-column(6);
			// @include grid-column-end;
		}
		@include breakpoint(large) {
			@include grid-column(4);
		}
	}
	.trend-card {
		@include grid-column(12);
		@include breakpoint(medium) {
			@include grid-column(6);
			// @include grid-column-end;
		}
		@include breakpoint(large) {
			@include grid-column(4);
		}
		.trend-card-inner {
			max-width: rem-calc(640);
			border: solid 1px #cccccc;
			background-color: $white;
			margin-bottom: rem-calc(20);
			margin-left: auto;
			margin-right: auto;
		}
		.color-band-wrapper {
			max-height: rem-calc(25);
			.post-cat {
				color: $white;
				text-transform: uppercase;
				font-size: rem-calc(16);
				line-height: rem-calc(25);
				font-weight: 900;
				padding-left: rem-calc(20);
				a, a:hover{
					color: $white;
				}
			}
		}
		.trend-card-name {
			padding-top: rem-calc(26);
			background-color: $white;
			color: $text-black;
			text-transform: uppercase;
			font-size: rem-calc(13);
			line-height: rem-calc(40);
			font-weight: 900;
			padding-left: rem-calc(20);
			padding-right: rem-calc(20);
		}
		.insight-desc {
			padding-left: rem-calc(20);
			padding-right: rem-calc(20);
			padding-bottom: rem-calc(20);
			color: $text-black;
			font-size: rem-calc(30);
			line-height: rem-calc(40);
			background-color: $white;
			-ms-word-break: break-all;
			     word-break: break-all;
			     // Non standard for webkit
			     word-break: break-word;
			-webkit-hyphens: auto;
			   -moz-hyphens: auto;
			        hyphens: auto;
		}
	}
	.views-row-odd {
		// padding-left: 0;
	}
	.views-row-even {
		// padding-right: rem-calc(20);
	}
}
#block-views-related-insight-grid-block-2 {
	@include grid-row($behavior: nest);
	padding-bottom: rem-calc(60);
	.view-header {
		margin-left: rem-calc(10);
	}
}
.view-related-insight-grid {

}
.node-type-trend #block-views-related-insight-grid-block-2 .view-content {
	.masonry-item {
		padding: 0;
		border: solid 0px #cccccc;
		background-color: transparent;
		margin-left: 0;
		margin-right: 0;
		margin: 0;
		@include grid-column(12);
		@include breakpoint(medium) {
			@include grid-column(6);
			// @include grid-column-end;
		}
		@include breakpoint(large) {
			@include grid-column(4);
		}
	}
	.trend-card {
		@include grid-column(12);
		@include grid-column-end;
		max-width: rem-calc(301);
		@include breakpoint(medium) {
			@include grid-column(6);
			max-width: rem-calc(301);
			@include grid-column-end;
		}
		@include breakpoint(large) {
			@include grid-column(4);
			max-width: 100%;
		}
		.trend-card-inner {
			max-width: rem-calc(640);
			border: solid 0px #cccccc;
			background-color: #e7e7e7;
			margin-bottom: rem-calc(20);
			margin-left: auto;
			margin-right: auto;
		}
		.color-band-wrapper {
			max-height: rem-calc(25);
			.post-cat {
				color: $white;
				text-transform: uppercase;
				font-size: rem-calc(16);
				line-height: rem-calc(25);
				font-weight: 900;
				padding-left: rem-calc(20);
				a, a:hover{
					color: $white;
				}
			}
		}
		.trend-card-name {
			padding-top: rem-calc(26);
			background-color: $white;
			color: $text-black;
			text-transform: uppercase;
			font-size: rem-calc(13);
			line-height: rem-calc(40);
			font-weight: 900;
			padding-left: rem-calc(20);
			padding-right: rem-calc(20);
		}
		.insight-desc {
			padding-left: rem-calc(20);
			padding-right: rem-calc(20);
			padding-bottom: rem-calc(20);
			color: $text-black;
			font-size: rem-calc(30);
			line-height: rem-calc(40);
			background-color: $white;
			-ms-word-break: break-all;
			word-break: break-all;
			// Non standard for webkit
			word-break: break-word;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			hyphens: auto;
		}
	}
}
.front {
	.view-content{
		.trend-card {
			.trend-card-inner {
				max-width: rem-calc(301);
				height: rem-calc(470);
				padding-bottom: rem-calc(20);
			}
		}
		.views-row-odd {
			padding-left: rem-calc(10);
		}
		.views-row-even {
			padding-right: rem-calc(10);
		}
	}
}
// .page-search {
// 	.trend-card {
// 		.trend-card-inner {
// 			max-width: rem-calc(301);
// 			height: rem-calc(526);
// 			padding-bottom: rem-calc(20);
// 		}
// 	}
// }
