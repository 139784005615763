// Top Nav
@charset 'utf-8';

// Hide admoin menu for testing.
// #admin-menu-wrapper {
// 	display: none;
// }
.title-bar {
	background-color: $white;
	z-index: 901;
	@include grid-column(12);
	.title-bar-left {
	    margin-top: rem-calc(5);
	}
}
.top-bar {
	float: left;
	padding-left: 0;
	padding-right: 0;
	position: relative;
	z-index: 905;
}
.top-bar-right {
	@include grid-column(12);
	float: left;
	@include breakpoint(medium){
		@include grid-column(5);
		float: right;
		z-index: 905;
		position: relative;
		.menu {
			float: right;
		}
	}
	.menu {
		float: left;
	}
	li {
		height: 30px;
		.fa-search {
			font-size: rem-calc(12);
		}
	}
	.menu > li > a {
		padding: rem-calc(4 12);
		font-size: rem-calc(16);
		.fa-map-marker {
			font-size: rem-calc(18);
		}
	}
	.menu > li.last > a {
		padding-right: 0;
	}
}
.top-bar-left {
	@include grid-column(12);
	@include breakpoint(medium){
		@include grid-column(7);
	}
}
.logo {
	height: rem-calc(16);
	vertical-align: top;
}

.top-bar .top-bar-title .menu-icon.dark,
.menu-icon.dark {
    @include hamburger($black,$black,20px,16px,4px,3);
    height: 16px;
    outline:none;
}
// motion test
.menu-icon-2 {
	@include hamburger($black,$black,20px,16px,3px,3);
	height: 16px;
	display: block;
}
#responsive-menu,
#navmenu {
	@include grid-column;
	display: none;
	@include mui-slide(in, down, 1s);
	z-index: 900;
	position: relative;
}
.trend-map {
	#Layer_1 {
	@include mui-zoom(in, $from:0.5 2, $duration:1s);
	}
}
// Off-Canvas
.off-canvas.position-left {
	height: 100vh;
	background-color: $light-gray;
	.close-button {
		font-weight: 900;
		outline : none;
	}
	.menu {
		margin-top: rem-calc(50);
	}
	.mm-item {
		text-transform: uppercase;
		margin-bottom: rem-calc(6);
		width: 90%;
		text-align: right;
		@include transition(width .5s ease);
		&:hover {
		    width: 95%;
		}
		a, a:link, a:hover {
			color: $white;
			font-weight: 900;
			font-family: 'Source Sans Pro', sans-serif;
			font-size: rem-calc(20);
			line-height: rem-calc(28);
			padding: 0 rem-calc(20);
		}
	}
	#retail-btn {
		background-color: $blue;
	}
	#food-beverage-btn {
		background-color: $peach;
	}
	#culture-btn {
		background-color: $green;
	}
	#style-btn {
		background-color: $magenta;
	}
	#travel-btn {
		background-color: $mustard;
	}
	#design-btn {
		background-color: $orange;
	}
}
.off-canvas.position-right {
	height: 100vh;
	background-color: $light-gray;
	.close-button {
		font-weight: 900;
		outline : none;
	}
	.menu {
		margin-top: rem-calc(50);
		li {
			text-transform: uppercase;
			margin-bottom: rem-calc(6);
			width: 90%;
			text-align: left;
			background-color: $text-black;
			@include transition(background-color .9s ease);
			&:hover {
			    // width: 95%;
					background-color: $dark-gray;
			}
			a, a:link, a:hover {
				color: $white;
				font-weight: 900;
				font-family: 'Source Sans Pro', sans-serif;
				font-size: rem-calc(20);
				line-height: rem-calc(28);
				padding: 0 rem-calc(20);
			}
		}
	}
	.primary-tabs {
		@include button-group-unstack(".button");
		margin-top: rem-calc(50);
		.button {
			background-color: $text-black;
			@include transition(background-color .9s ease);
			&:hover {
			    // width: 95%;
					background-color: $dark-gray;
			}
			text-transform: uppercase;
			margin-bottom: rem-calc(6);
			width: 90%;
			text-align: left;
			color: $white;
			font-weight: 900;
			font-family: 'Source Sans Pro', sans-serif;
			font-size: rem-calc(16);
			line-height: rem-calc(28);
			padding: 0 rem-calc(20);
		}
	}
	#secondary-menu {
		margin-top: 0;
	}
}
// Footer Nav
#block-menu-menu-footer-nav {
	.menu {
		@include menu-simple;
		li {
			color: $white;
			font-size: rem-calc(20);
			cursor: default;
			display: inline-block;
			font-family: 'Source Sans Pro';
			font-weight: 900;
			margin-right: rem-calc(4);
		&:not(:last-child)::after {
				color: $white;
				content: '/';
				position: relative;
				top: 0;
				font-weight: 900;
			}
			a {
				color: $white;
				font-size: rem-calc(20);
				line-height: rem-calc(24);
				font-family: 'Source Sans Pro';
				font-weight: 900;
				text-transform: uppercase;
				display: inline-block;
				padding-right: rem-calc(8);
				&:hover {
				  text-decoration: none;
				}
			}

		}
	}
}
#block-menu-menu-social-menu {
	padding-bottom: rem-calc(20);
	.footer-nav-btn {
	  height: 40px;
	  text-indent: 9999px;
	  overflow: hidden;
	  display: inline-block;
	  margin-right: rem-calc(42);
	}
	#ig-btn {
	  background: url('../img/images/footer-social-icons.png') no-repeat 0 0;
	  width: 38px;
	}
	#twitter-btn {
	  background: url('../img/images/footer-social-icons.png') no-repeat -78px 0;
	  width: 47px;
	}
	#fb-btn {
	  background: url('../img/images/footer-social-icons.png') no-repeat -168px 0;
	  width: 42px;
	}
	#pinterest-btn {
	  background: url('../img/images/footer-social-icons.png') no-repeat -250px 0;
	  width: 42px;
	}
}
