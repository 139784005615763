 body, p {
 	font-family: 'Source Sans Pro';
 	font-weight: 300;
 	font-size: rem-calc(20);
 	line-height: rem-calc(30);
 }
 .body,
 .copy-link {
 	a:link {
 		border-bottom: 2px solid $text-black;
 	}
 }
.credit-line {
	font-size: rem-calc(16);
	line-height: rem-calc(24);
	padding-top: rem-calc(10);
  font-weight: 500;
	a {
		color: $white;
		text-transform: uppercase;
	}
}
.body ul,
.field-page-section ul {
  list-style-type: none;
  margin-left: 0;
  li {
      background: url("../img/images/bullet.gif") no-repeat left 16px;
      padding-left: 1rem;
  }
}
.field-insight-description {
	color: $dark-gray;
	a, a:hover {
		color: $dark-gray;
	}
}
