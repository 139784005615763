.subhead-large {
	font-size: rem-calc(20);
	line-height: rem-calc(30);
	font-weight: 900;
	text-transform: uppercase;
	color: $text-black;
	margin: 0;
}
.section-head,
.page-node-13 .field-title-field {
	font-size: rem-calc(20);
	line-height: rem-calc(30);
	color: $text-black;
	margin: 0;
	font-weight: normal;
}
.rteindent1 {
    margin-left: 0;
}
