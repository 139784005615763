@charset 'utf-8';
.node-type-trend {
	p {
		font-size: rem-calc(30);
		line-height: rem-calc(40);
	}
	.color-band-wrapper {
		// background-color: $blue;
		height: rem-calc(25);
		color: $white;
		.color-band {
			@include grid-column-row;
			font-size: rem-calc(16);
			line-height: rem-calc(25);
			text-transform: uppercase;
			font-weight: 900;
		}
	}
	.main-container {
		background-color: $light-gray;
		.field-ratings {
			margin-bottom: rem-calc(40);
		}
		.field-insight-description {
			padding-bottom: rem-calc(48);
		}
		.gmp-div {
			@include grid-column;
			padding-left: 0;
			padding-bottom: rem-calc(48);
			.adr{
				display: inline-block;
				font-size: rem-calc(20);
				margin-bottom: rem-calc(50);
			}
			.location {
				display: inline-block;
				padding-left: rem-calc(10);
				text-transform: uppercase;
				font-size: rem-calc(14);
				font-weight: 500;
			}
		}
		.submitted,
		h3.field-label {
			font-size: rem-calc(20);
			line-height: rem-calc(40);
			font-weight: 900;
			text-transform: uppercase;
			color: #373232;
			margin-top: rem-calc(50);
		}
		h3.field-label {
			line-height: rem-calc(30);
		}
		.body {
			p {
				// padding-bottom: rem-calc(48);
			}
		}
	}
	h1 {
		padding-top: rem-calc(158);
		padding-bottom: rem-calc(16);
		font-size: rem-calc(80);
		line-height: rem-calc(60);
	}
	.field-photo-credit {
		p {
			font-size: rem-calc(20);
			line-height: rem-calc(30);
		}
	}
	.block-bean-insight-post-bottom h1 {
		padding-top: rem-calc(0);
		padding-bottom: rem-calc(0);
	}
}
// h3.field-label {
// 	color: $dark-gray;
// 	font-size: rem-calc(20);
// 	text-transform: uppercase;
// 	line-height: rem-calc(30);
// 	font-weight: 900;
// 	margin-top: rem-calc(50);
// }
.node-type-trend #row-callout-wrap {
	position: relative;
	z-index: 1;
	height: rem-calc(300);
	.row-callout-bg {
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: url('../img/images/insight-map.png') no-repeat center -164px;
		opacity: 0.1;
		width: 100%;
		height: 100%;
	}
	.row-callout {
		padding-top: rem-calc(112);
		opacity: 1;
	}
	#block-bean-insight-post-bottom {
		@include grid-column(11);
		padding-left: 0;
		a, a:hover {
			text-decoration: underline;
		}
	}
}
// .trend-map {
// 	@include grid-column-row;
// 	left: 0;
// }
// .trend-map {
// 	display: inline-block;
// 	position: relative;
// 	width: 100%;
// 	// padding-bottom: 100%;
// 	vertical-align: middle;
// 	overflow: hidden;
// }
// #Layer_1 {
// 	display: inline-block;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// }
// Color bands
.taxonomy-retail .color-band-wrapper,
.color-band-384 {
	background-color: $blue;
}
.taxonomy-food--beverage .color-band-wrapper,
.color-band-385 {
	background-color: $peach;
}
.taxonomy-culture .color-band-wrapper,
.color-band-386 {
	background-color: $green;
}
.taxonomy-style .color-band-wrapper,
.color-band-387 {
	background-color: $magenta;
}
.taxonomy-travel .color-band-wrapper,
.color-band-388 {
	background-color: $mustard;
}
.taxonomy-design .color-band-wrapper,
.color-band-389 {
	background-color: $orange;
}
