.top-bar-right {
  a, a:hover {
    color: $black;
  }
}
.box {
  height: 30px;
  padding-right: rem-calc(16);
}

.container-2 {
  width: 300px;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
}

.container-2 input[type='search']#edit-search-block-form--2,
.container-2 [type='search']{
  width: 30px;
  height: 32px;
  border: none;
  font-size: re-calc(13);
  line-height: rem-calc(22);
  float: right;
  color: $text-black;
  padding-right: 24px;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 4;
  opacity: 1;
  box-shadow: none;
  -webkit-transition: width .55s ease;
  -moz-transition: width .55s ease;
  -ms-transition: width .55s ease;
  -o-transition: width .55s ease;
  transition: width .55s ease;
}
.container-2 input[type='search']#edit-search-block-form--2::-webkit-input-placeholder {
  opacity: 1;
   font-size: re-calc(13);
   color: $text-black;
   font-family: $body-font-family;
   line-height: rem-calc(36);
   text-transform: uppercase;
   font-weight: 500;
   font-style: italic;
   padding-bottom: 0;
   vertical-align: bottom;
}

.container-2 input[type='search']#edit-search-block-form--2:-moz-placeholder { /* Firefox 18- */
  opacity: 1;
  font-size: re-calc(13);
  color: $text-black;
  font-family: $body-font-family;
  line-height: rem-calc(36);
  text-transform: uppercase;
  font-weight: 500;
  font-style: italic;
  padding-bottom: 0;
  vertical-align: bottom;

}

.container-2 input[type='search']#edit-search-block-form--2::-moz-placeholder {  /* Firefox 19+ */
  opacity: 1;
  font-size: re-calc(13);
  color: $text-black;
  font-family: $body-font-family;
  line-height: rem-calc(36);
  text-transform: uppercase;
  font-weight: 500;
  font-style: italic;
  padding-bottom: 0;
  vertical-align: bottom;
}

.container-2 input[type='search']#edit-search-block-form--2:-ms-input-placeholder {
  opacity: 1;
  font-size: re-calc(13);
  color: $text-black;
  font-family: $body-font-family;
  line-height: rem-calc(36);
  text-transform: uppercase;
  font-weight: 500;
  font-style: italic;
  padding-bottom: 0;
  vertical-align: bottom;
}

.container-2 .icon {
  position: absolute;
  top: 5px;
  right: rem-calc(4);
  margin-left: 20px;
  line-height: rem-calc(22);
  display:block;
  z-index: 3;
  color: #000;
  background-color: transparent;
  .material-icons {
    background-color: transparent;
    // font-size: rem-calc(24);
    // line-height: rem-calc(36);
    z-index: 1;
    :hover {
      color: $black;
    }
  }
}

.container-2 input#edit-search-block-form--2:focus, .container-2 input#edit-search-block-form--2:active{
  outline:none;
  width: 300px;
  border-bottom: solid 1px #65737e;
}

.container-2:hover input#edit-search-block-form--2{
  width: 300px;
  border-bottom: solid 1px #65737e;
}

.container-2:hover .icon{
  color: $black;
}
.galleria {
  height: 450px;
  .galleria-container {
    background-color: $white;
  }
}
// Fivestar
.fivestar-average-stars,
.fivestar-static-item {
  // @include grid-column(2);
  display: inline-block;
  padding-left: 0;
  .star {
    margin-right: 7px;
  }
}
form.fivestar-widget {
    clear: both;
    display: inline-block;
}
div.fivestar-widget-static .star {
  margin-right: 7px;
}
.star-label {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  vertical-align: top;
  display: inline-block;
  text-indent: 10px;
  text-transform: uppercase;
  font-style: italic;
  color: #8c8c8c;
}
// Reveal
.reveal {
  position:absolute;
  top:50%!important; /* override inline style injected from JS */
  left:50%;
  transform:translate(-50%, -50%);
}
// Location
.location.vcard {
  .adr {
    span {
      display: inline-block;
    }
  }
}
.map-link {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.map-key {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 12px;
}
.country-name {
  //float: left;
}
.trend-map {
  @include reveal-modal-fullscreen;
  #Layer_1 {
    width: 100%;
    height: 100hv;
  }
}
// pager
.item-list {
  .pager{
    text-transform: uppercase;
    margin: 1.75em 0;
    padding: 0;
    .pager_current{
      font-weight: 500;
    }
    .pager-next {
      font-weight: 500;
    }
    .pager-previous a,
    .pager-current,
    .pager-next a {
      color: $dark-gray;
      font-weight: 500;
      font-size: rem-calc(18);
      line-height: rem-calc(24);
    }
  }
}

// Tabs
// .tab .button-group {
//   @include button-group('.button');
//   @include button-group-stack('.button');
// }
