.field-page-section {
	padding-bottom: rem-calc(30);
}
// Front
.front #block-bean-insight-post-bottom {
  a, a:hover {
    text-decoration: underline;
  }
}
.front .main-container {
  position: relative;
  z-index: 1;
  height: auto;
	padding-bottom: rem-calc(80);
  #block-bean-insight-post-bottom {
    padding-top: rem-calc(68);
    padding-bottom: rem-calc(68);
    line-height: rem-calc(32);
  }
  .full-width-bg {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('../img/images/insight-map.png') no-repeat center -30px fixed;
    background-size: 120%;
    // -webkit-background-size: cover;
    //  -moz-background-size: cover;
    //  -o-background-size: cover;
    //  background-size: cover;
    opacity: 0.1;
    width: 100%;
    height: 100%;
  }
}
.front #row-callout-wrap {
    background-color: $dark-gray;
    padding-top: rem-calc(160);
    padding-bottom: rem-calc(154);
		color: $white;
    .section-head {
			color: $white;
      text-transform: uppercase;
      font-weight: 500;
    }
    h1 {
      font-size: rem-calc(100);
      line-height: rem-calc(90);
      margin-bottom: rem-calc(16);
    }
    p {
      font-size: rem-calc(20);
      line-height: rem-calc(30);
      text-transform: uppercase;
      font-weight: 900;
    }
    a, a:hover {
      color: $white;
      text-decoration: underline;
    }
}
.front .l-featured {
  .hp-trend-card {
    // background-color: $light-gray;
    // opacity: 0.1;
  }
  .hp-main-feature {
		// -webkit-background-size:cover;
		// -moz-background-size:cover;
		// background-size:cover;
		// background-position: center center;
		z-index: 1;
		position: relative;
		color: $white;
		min-height: rem-calc(200);
		max-height: rem-calc(640);
		overflow: hidden;
		@include breakpoint(medium){
			min-height: rem-calc(426);
		}
		@include breakpoint(landscape) {
    	min-height: rem-calc(370);
		}
    img {
			position: absolute;
	  	top: 50%;
	  	transform: translateY(-50%);
			overflow: hidden;
			z-index: 1;
    }
    .container {
			@include grid-row;
      .hp-main-feature-inner {
				padding-left: rem-calc(10);
				padding-right: rem-calc(10);
        position: absolute;
        bottom: 0;
				// top: 0;
        max-width: rem-calc(960);
        padding-bottom: rem-calc(0);
				@include breakpoint(medium){
						padding-bottom: rem-calc(28);
				}
				z-index: 9997;
        .trend-name {
          text-transform: uppercase;
          font-weight: 900;
          font-size: rem-calc(13);
          line-height: rem-calc(40);
        }
       p, a:link, a:hover, a:visited {
         // font-weight: 500;
         font-size: rem-calc(36);
         line-height: rem-calc(36);
				 @include breakpoint(medium){
					font-size: rem-calc(56);
	 				line-height: rem-calc(56);
 		    }
         color: $white;
         a:link, a:hover, a:visited {
          color: $white;
          text-decoration: underline;
         }
       }
      }
    }
  }
	.hp-main-feature::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		// bottom: 0;
		left: 0;
		background-image: linear-gradient(to bottom, rgba(0,0,0, 0),rgba(0,0,0, 0.5));
		// background-color:rgba(30, 28, 28, 0.5);
		// background: url(data:;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABl0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuNUmK/OAAAAATSURBVBhXY2RgYNgHxGAAYuwDAA78AjwwRoQYAAAAAElFTkSuQmCC) repeat scroll transparent\5;
		// min-height: rem-calc(300);
		max-height: rem-calc(426);
		overflow: hidden;
		@include breakpoint(medium){
			min-height: rem-calc(426);
		}
		z-index: 2;
	}
  .post-cat {
    @include grid-col-row();
    color: $white;
    font-size: rem-calc(16);
    line-height: rem-calc(25);
    text-transform: uppercase;
    font-weight: 900;
    a, a:hover {
      color: $white;
      text-decoration: none;
    }
  }
}
.front #block-bean-close-up-block {
  color: $white;
  .section-head {
    text-transform: uppercase;
    font-size: rem-calc(30);
    line-height: rem-calc(60);
		font-weight: 900;
  }
}
//Terms and conditions
.page-terms-use {
	.body {
		padding-bottom: rem-calc(30);
	}
	p {
		max-width: rem-calc(900);
	}
}
// Contribute
// Contact
#user-login,
#user-pass,
#user-register-form,
#webform-client-form-77,
#emma-signup-subscribe-form,
#mailchimp-newsletter-398a475b82-mergefields,
.mailchimp-signup-subscribe-form {
  margin-top: rem-calc(0);
  padding-bottom:rem-calc(47);
  .webform-component--message {
    margin-bottom: 0;
    border-top: solid 0px $black;
  }
  .webform-element-wrap.webform-component-textarea {
		margin-top: rem-calc(20);
  }
  .webform-element-wrap.form-item {
  		margin-top: rem-calc(20);
  }
  input,
  textarea,
  textarea[rows] {
  	font-weight: 400;
  	color: $text-black;
    font-size: rem-calc(13);
    line-height: rem-calc(16);
		// display: inline-block;
		// vertical-align: bottom;
    box-shadow: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 3px solid $text-black;
    padding-left: 0;
  	padding-bottom: 0;
    // min-height: 24px;
  }
  textarea {
  	padding-top: rem-calc(20);
  	resize: none;
  	min-height: 30px;
  	max-height: 300px;
	// height: auto;
  }
  input[type=text],
  input[type="email"],
  input[type="password"] {
	  	// line-height: rem-calc(40);
  	padding-bottom: 0;
  	margin-bottom: 0;
  	color: $text-black;
  	padding-left: 0;
  	padding-top: rem-calc(0);
  	height: 24px;
  }
  .form-submit {
    // float: right;
    @include button(false, $white, $white, $text-black);
    @include button-hollow;
    font-size: rem-calc(20);
    line-height: rem-calc(24);
    font-weight: 900;
    font-family: $body-font-family;
    color: $dark-gray;
    border-bottom:solid 3px $dark-gray;
    text-transform: uppercase;
    bottom: 0;
    left: 0;
    top: rem-calc(-53);
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    margin-bottom: rem-calc(6);
  }
.region-center-column {
	@include grid-column;
}
//  	textarea[rows]::-webkit-input-placeholder,
// 	input[type="email"]::-webkit-input-placeholder,
//   input[type="text"]::-webkit-input-placeholder,
//   input[type="password"]#edit-pass::-webkit-input-placeholder {
//  	opacity: 0.5;
//     color: $text-black;
//     font-family: $body-font-family;
//     font-size: rem-calc(13);
//     line-height: rem-calc(16);
//     text-transform: uppercase;
//     font-weight: 500;
//     font-style: italic;
//     padding-bottom: 0;
// 		display: inline-block;
//     vertical-align: text-bottom;
// 		height: 24px;
// 		overflow: hidden;
//   }
.form-required {
	color: $text-black;
}
::-webkit-input-placeholder {
	opacity: 0.5;
	color: $text-black;
	font-family: $body-font-family;
	font-size: rem-calc(13);
	line-height: rem-calc(16);
	text-transform: uppercase;
	font-weight: 500;
	font-style: italic;
	padding-bottom: 0;
	display: inline-block;
	vertical-align: text-bottom;
	height: 24px;
	overflow: hidden;
}
::-moz-placeholder {
	opacity: 0.5;
	color: $text-black;
	font-family: $body-font-family;
	font-size: rem-calc(13);
	line-height: rem-calc(16);
	text-transform: uppercase;
	font-weight: 500;
	font-style: italic;
	padding-bottom: 0;
	display: inline-block;
	vertical-align: text-bottom;
	height: 24px;
	overflow: hidden;
}
:-ms-input-placeholder {
	opacity: 0.5;
	color: $text-black;
	font-family: $body-font-family;
	font-size: rem-calc(13);
	line-height: rem-calc(16);
	text-transform: uppercase;
	font-weight: 500;
	font-style: italic;
	padding-bottom: 0;
	display: inline-block;
	vertical-align: text-bottom;
	height: 24px;
	overflow: hidden;
}
:-moz-placeholder {
	opacity: 0.5;
	color: $text-black;
	font-family: $body-font-family;
	font-size: rem-calc(13);
	line-height: rem-calc(16);
	text-transform: uppercase;
	font-weight: 500;
	font-style: italic;
	padding-bottom: 0;
	display: inline-block;
	vertical-align: text-bottom;
	height: 24px;
	overflow: hidden;
}
//   input[type="email"]::-webkit-input-placeholder,
//   input[type="text"]::-webkit-input-placeholder,
//   input[type="password"]::-webkit-input-placeholder {
//   	padding-top: rem-calc(30);
//   	height: 24px;
// 		overflow: hidden;
//   }
//   input[type="email"]::-moz-placeholder,
//   input[type="text"]::-moz-placeholder,
//   input[type="password"]::-moz-placeholder,
//   textarea::-moz-placeholder {
//   	opacity: 0.5;
//     color: $text-black;
//     font-family: $body-font-family;
//     font-size: rem-calc(13);
//     line-height: rem-calc(16);
//     // padding-top: rem-calc(20);
//     text-transform: uppercase;
//     font-weight: 500;
//     font-style: italic;
// 		display: inline-block;
//     vertical-align: text-bottom;
// 		height: 24px;
// 		overflow: hidden;
//   }
//   input[type="email"]:-ms-input-placeholder,
//   input[type="text"]:-ms-input-placeholder,
//   input[type="password"]:-ms-input-placeholder,
//   textarea:-ms-input-placeholder {
//   	opacity: 0.5;
//      color: $text-black;
//      font-family: $body-font-family;
//      font-size: rem-calc(13);
//      line-height: rem-calc(16);
//      text-transform: uppercase;
//      font-weight: 500;
//      font-style: italic;
// 		 display: inline-block;
//      vertical-align: text-bottom;
// 		 height: 24px;
// 		 overflow: hidden;
//   }
}
#mailchimp-newsletter-398a475b82-mergefields {
  padding-bottom: rem-calc(15);
}

.webform-component--leave-this-field-blank,
.webform-component--not-your-field,
.mollom-privacy {
  display: none;
  color: #939393;
  font-family: $body-font-family;
  font-size: rem-calc(24);
  line-height: rem-calc(30);
}
.g-recaptcha {
  padding-bottom: rem-calc(32);
  padding-top: rem-calc(32);
}
// Register
.page-user-login,
.page-user,
.page-user-register,
.page-contribute,
.page-node-78 {
  .field-bean-text {
    p {
      font-size: rem-calc(30);
      line-height: rem-calc(40);
    }
  }
	#block-bean-sign-up-legalese,
	#block-bean-contribute-legalese,
	#block-bean-subscribe-legalese {
		padding-left: rem-calc(0);
		padding-bottom:rem-calc(87);
		p {
			font-size: rem-calc(16);
			line-height: rem-calc(24);
			padding-top: rem-calc(10);
		  font-weight: 500;
		}
		a {
			color: $text-black;
			text-decoration: underline;
		}
	}
}
.page-user-register #edit-mailing-list {
	label {
		font-size: rem-calc(16);
		line-height: rem-calc(24);
		padding-top: rem-calc(10);
		font-weight: 500;
		text-transform: none;
		font-style: normal;
	}
}
.page-user-login .main-container {
  position: relative;
  z-index: 1;
  height: auto;
	padding-bottom: rem-calc(80);
	background-color: #f3f3f3;
  // #block-bean-insight-post-bottom {
  //   padding-top: rem-calc(68);
  //   padding-bottom: rem-calc(68);
  //   line-height: rem-calc(32);
  // }
  .full-width-bg {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('../img/images/insight-map.png') no-repeat center -80px fixed;
    background-size: 150%;
    // -webkit-background-size: cover;
    //  -moz-background-size: cover;
    //  -o-background-size: cover;
    //  background-size: cover;
    opacity: 0.1;
    width: 100%;
    height: 100%;
  }
	.tabs,
	#user-login input {
		background-color: transparent;
	}
	.tabs {
		margin-top: rem-calc(24);
	}
}
.page-node-412 .trend-map {
  @include reveal-modal-fullscreen;
  @include reveal-overlay(rgba(255, 255, 255, 0.75));
  #Layer_1 {
    width: 100%;
    height: 100hv;
  }
}
.page-node-412 #block-system-main {
  min-height: 90vh;
}