// Drupal comes with CSS quirks (Drupalisms). Unravel or override them here.

#admin-menu {
  display: none;
  @include breakpoint(large){
    display: block;
  }
}
html body.admin-menu {
  margin-top: 0 !important;
  @include breakpoint(large){
    margin-top: 29px !important;
  }
}
.tasks-block {
  // background-color:$medium-gray;
  @include grid-row;
  width: auto;
  .tabs {
    border: 0px solid;
  }
  .button-group {
    margin-bottom: 0;
    @include grid-column;
    border: 0px solid;
  }
  .button {
    margin-bottom: 0;
  }
}
.logged-in .tasks-block {
  top: 160px;
}
.not-logged-in {
  .harlow-callout {
    margin-top: rem-calc(56);
    margin-bottom: 0;
    background-color: $blue;
    @include callout-style($color: $blue)
  }
}
.harlow-callout,
.callout.success {
  margin-top: rem-calc(26);
  margin-bottom: 0;
  background-color: $blue;
  @include callout-style($color: $blue)
}
.serv-tabs {
  .tabs-wrapper {
    @include grid-row;
    .button-group {
      @include grid-column(9);
      @include grid-column-end;
      @include button-group-expand(".button");
      .button {
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
}
.tabs {
  border: 0px solid $white;
  @include breakpoint(large){
    @include grid-column(9);
    @include grid-column-end;
    padding-left: 0;
  }
  .button-group {
  @include breakpoint(medium){
    @include button-group-expand(".button");
    // border-right: 0 solid $white;
  }
    .button {
      // border-right: 20px solid $white;
      // border-top: 20px solid $white;
      // border-bottom: 20px solid $white;
      text-transform: uppercase;
      font-weight: 900;
      @include breakpoint(medium){
        // border-right: 20px solid $white;
        // border-top: 2px solid $white;
        // border-bottom: 2px solid $white;
      }
    }
    .button.active {
      background-color:$dark-gray;
    }
  }
}
.harlow-user-login-form-wrapper,
#user-pass {
  clear:both;
}
label {
  opacity: 1;
  color: $text-black;
  font-family: $body-font-family;
  font-size: rem-calc(13);
  line-height: rem-calc(16);
  display: inline-block;
  vertical-align: text-bottom;
  padding-top: rem-calc(20);
  text-transform: uppercase;
  font-weight: 500;
  font-style: italic;
}
.visually-hidden {
  @include element-invisible;
}
// Remove the bottom padding on status messages when displayed inside a Zurb
// Foundation Reveal modal.
#status-messages.reveal-modal {
  .alert-box {
    margin-bottom: 0;
  }
}

// Fix Zurb Foundation Reveal Modal z-index and make it play nice with dropdown
// menus, lightboxes, etc.
.reveal-modal {
  z-index: 1005;
}

// Override Drupal Core pager styles. This is necessary because we need to keep
// the .pager class on pagers for Views AJAX to work. If you are disabling
// Drupal Core CSS you can safely comment the following lines.
// .item-list .pager {
//   clear: none;
// }
// .item-list .pager li {
//   padding: 5px;
// }
/* Fix Core Throbber */
/* Animated throbber */
html.js input.form-autocomplete {
  background-image: url("../images/misc/throbber.gif");
  background-position: 100% -24px; /* LTR */
  background-repeat: no-repeat;
}
html.js input.throbbing {
  background-position: 100% 24px; /* LTR */
}
.ajax-progress {
  display: inline;
  vertical-align: top !important;
}
.ajax-progress .throbber {
  background: transparent url("../images/misc/throbber.gif") no-repeat 100% 24px;
  float: left; /* LTR */
  height: 16px;
  margin: 2px;
  width: 16px;
}
.ajax-progress .message {
  padding-left: 20px;
}
tr .ajax-progress .throbber {
  margin: 0 2px;
}
.ajax-progress-bar {
  width: 16em;
}
input.form-checkbox, input.form-radio {
  vertical-align: baseline;
}
